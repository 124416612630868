import React,{useState, useEffect} from 'react';
import { Link, Routes, useLocation, Route } from 'react-router-dom';

import auth from '../../utils/auth';
import PrivateRoute from '../PrivateRoute';
import Dashboard from '../Dashboard';
import RelatorioJornadaTrabalho from '../RelatorioJornadaTrabalho';
import RelatorioAbastecimento from '../RelatorioAbastecimento';

import '../../assets/style.css';
import RelatorioBomba from '../RelatorioBomba';

export default function Layout(props) {

    const [menuActive, setMenuActive] = useState("dashboard")
    const [menuToggle, setMenuToggle] = useState(false)
    const [title, setTitle] = useState("Estátisticas")

    const location = useLocation();

    const set_path = (path) => {
        
        if (path === undefined){
            path = location.pathname.split('/')[1]
        }
        setMenuActive((path) ? path : "dashboard")

        switch (path) {
            case 'dashboard':
                setTitle("Estátisticas")
                break;
            case 'jornada':
                setTitle("Jornada de Trabalho")
                break;
            case 'abastecimento':
                setTitle("Abastecimento")
                break;
            case 'bomba':
                setTitle("Bomba de Abastecimento")
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        set_path()
    }, [])

	return (
        <>
            <div className="main-app-box">
                <main>
                    <nav>
                        <div className="menu-info">
                            <div className="logo-menu">
                                <div>
                                    <div> <img className="logo" src="/logo.png" /></div>
                                </div>
                                <div className="menu-system">
                                    <ul>
                                        <li><Link onClick={() => set_path('dashboard')} to="/">Dashboard</Link></li>
                                        <li><Link onClick={() => set_path('jornada')} to="/jornada/">Jornada de Trabalho</Link></li>
                                        <li><Link onClick={() => set_path('abastecimento')} to="/abastecimento/">Abastecimento</Link></li>
                                        <li><Link onClick={() => set_path('bomba')} to="/bomba-abastecimento/">Bomba de Abastecimento</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="menu-noti-exit">
                                <div className='menu-mobile'>
                                    <svg onClick={() => setMenuToggle(!menuToggle)} width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zm1 4a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2H3z" fill="#000000"/></svg>
                                </div>
                                <div onClick={() => { auth.clearAppStorage(); window.location.reload() }} className="button-menu-top button-exit">Encerrar</div>
                                <div style={{display: (menuToggle) ? "flex" : "none" }} className="menu-system-mobile">
                                    <ul>
                                        <li><Link onClick={() => set_path('dashboard')} to="/">Dashboard</Link></li>
                                        <li><Link onClick={() => set_path('jornada')} to="/jornada/">Jornada de Trabalho</Link></li>
                                        <li><Link onClick={() => set_path('abastecimento')} to="/abastecimento/">Abastecimento</Link></li>
                                        <li><Link onClick={() => set_path('bomba')} to="/bomba-abastecimento/">Bomba de Abastecimento</Link></li>
                                        <li><span className='mobile-exit' onClick={() => { auth.clearAppStorage(); window.location.reload() }} >Encerrar</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="breadcrumbs">
                        <div>
                            {title}
                        </div>
                    </div>
                    <section className="content">
                        <div>
                            <Routes>
                                <Route path='/' element={<PrivateRoute />}>
                                    <Route exact path='/' element={<Dashboard />}/>
                                    <Route exact path='/jornada/*' element={<RelatorioJornadaTrabalho />}/>
                                    <Route exact path='/abastecimento/*' element={<RelatorioAbastecimento />}/>
                                    <Route exact path='/bomba-abastecimento/*' element={<RelatorioBomba />}/>
                                </Route>
                            </Routes>
                        </div>
                    </section>
                    <footer>
                        <div>
                            <a className='footer-link' target="_blank" href="https://www.auvorastrear.com.br/">https://www.auvorastrear.com.br/</a>
                        </div>
                    </footer>
                </main>
            </div>
        </>
	)
}
