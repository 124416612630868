import React from 'react';
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from './containers/PrivateRoute'
import Layout from './containers/Layout'
import Login from './containers/Login'

export default function App(props) {
	return (
		<>
			<Routes>
				<Route exact path='/login' element={<Login />}/>
				<Route exact path='/*' element={<PrivateRoute />}>
					<Route exact path='*' element={<Layout />}/>
				</Route>
			</Routes>
		</>
	)
}
