import React,{useState, useEffect} from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';

export default function Dashboard(props) {

	return (
        <>
            <img width="100%" src="/home_truck.jpg" />
        </>
	)
}
