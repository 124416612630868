import React,{useState, useEffect, useCallback} from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import axios from 'axios';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import cfg from '../../utils/config'
import auth from '../../utils/auth'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function RelatorioBomba(props) {

    const [drivers, setDrivers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [customer, setCustomer] = useState(false)
    const [sensors, setSensors] = useState([])
    const [sensor, setSensor] = useState(false)
    const [driver, setDriver] = useState(false)
    const [vehicle, setVehicle] = useState(false)
    const [date_in, setDate_in] = useState(false)
    const [date_out, setDate_out] = useState(false)
    const [tolerance, setTolerance] = useState(3600)
    const [logo, setLogo] = useState("")
    const [fnDriver, setFnDriver] = useState("")
    const [company, setCompany] = useState("")
    const [data, setData] = useState({list: [], resume: {odometer: '', liters: '', price: ''}})
    const [pdfLocked, setPdfLocked] = useState(true)
    const [runReport, setRunReport] = useState(false)
    const [modal, setModal] = useState(false)
    const [searchType, setSearchType] = useState('driver')
    const [macros, setMacros] = useState([])

    useEffect(() => {
        getInitialData()
    }, [])

    const getInitialData = () => {
        let url = cfg.base_api_url + 'fuel-supply/'
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        axios.get(url, config_me)
        .then((initial) => {
            //setDrivers(initial.data.drivers.drivers)
            setCustomer(initial.data.drivers.id)
            setVehicles(initial.data.vehicles)
        })
        .catch((request) => {
            console.log(request)
        })
    }

    const showModal = (list) => {
        setMacros(list)
        setModal(!modal)
    }

    const generateReport = () => {
        setRunReport(true)
        let url = cfg.base_api_url + 'fuel-supply-pump/'
        let token = auth.getToken()
        let userInfo = auth.getUserInfo()

        const config_me = { headers: { 'Authorization': token+'|'+userInfo.id} };

        let data = new FormData()
        data.append('date_in', date_in)
        data.append('date_out', date_out)
        data.append('customer', customer)
        
        data.append('tolerance', (tolerance <= 0) ? 3600 : tolerance)
        data.append('vehicle', vehicle)
        if (driver)
            data.append('driver', driver)
        
        if (sensor)
            data.append('sensor', sensor)

        axios.post(url, data, config_me)
        .then((report) => {
            setData(report.data)
            setPdfLocked(false)
            setRunReport(false)
        })
        .catch((request) => {
            console.log(request)
            setRunReport(false)
        })
    }

    const generateHtml = () => {

        let BRAZILIANREAL = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        let html_list = data.list.map((evt, key) => {
            console.log((evt.id_pump !== undefined))
            return (
                <tr key={key}>
                    <td>{evt.data}</td>
                    <td>{evt.weekday}</td>
                    <td>{evt.ref}</td>
                    {(evt.place !== undefined) ? <td>{evt.place}</td> : null}
                    <td>{evt.attendant}</td>
                    <td>{evt.liters}</td>
                    {(evt.km !== undefined) ? <td>{evt.km}</td> : null}
                    <td>{evt.pump}</td>
                    {(evt.id_pump !== undefined) ? <td>{evt.id_pump}</td> : null}
                </tr>
            )
        })

        if (data.resume.km_diff){
            html_list.push(
                <>
                    <tr key={"final_resume"} className='sub-close'>
                        <td colSpan={(data.list[0]?.place !== undefined) ? '5' : '4'}>Resumo do Período</td>
                        <td>{data.resume.liters} litros</td>
                        <td>{data.resume.km_diff} km</td>
                        <td colSpan='2'></td>
                    </tr>
                    <tr key={"final_resume_second_line"} className='sub-close'>
                        <td colSpan={(data.list[0]?.place !== undefined) ? '5' : '4'}>Média do Período</td>
                        <td colSpan='2'>{data.resume.medium.toFixed(2)} Km por litro</td>
                        <td colSpan='2'></td>
                    </tr>
                </>
            )
        }else{
            html_list.push(
                <tr key={"final_resume"} className='sub-close'>
                    <td colSpan={(data.list[0]?.place !== undefined) ? '5' : '4'}>Resumo do Período</td>
                    <td>{data.resume.liters} litros</td>
                    <td colSpan='3'></td>
                </tr>
            )
        }

        return html_list

    }

    const getSensor = (unidade) => {

        let url = cfg.base_api_url + 'sensor/?unidade='+unidade
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        axios.get(url, config_me)
        .then((initial) => {
            setSensors(initial.data.sensors)
            setSensor(false)
        })
        .catch((request) => {
            console.log(request)
        })

    }

    const getDrivers = (customer) => {

        let url = cfg.base_api_url + 'drivers/?custom='+customer
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        axios.get(url, config_me)
        .then((initial) => {
            setDrivers(initial.data.drivers.drivers)
            setDriver(false)
        })
        .catch((request) => {
            console.log(request)
        })

    }

    const readLogo = (elem) => {
        
        if (!elem.target.files || !elem.target.files[0]) return;
          
        const FR = new FileReader();
          
        FR.addEventListener("load", function(evt) {
            setLogo(evt.target.result)
        })
          
        FR.readAsDataURL(elem.target.files[0]);
        
    }

    const exportarPDF = () => {

        let desc_ref = "Bomba"
        let ref = ""
        ref = vehicles.filter((vehicle_f) => {
            return vehicle_f.id == vehicle
        })[0].nm
        
        let prepare_data = []
        let BRAZILIANREAL = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        data.list.forEach((evt, key) => {

            if (evt.km !== undefined){

                prepare_data.push([
                        evt.data,
                        evt.weekday,
                        evt.ref,
                        evt.attendant,
                        evt.liters,
                        evt.km,
                        evt.pump,
                    ]
                )
            }else{
                prepare_data.push([
                    evt.data,
                    evt.weekday,
                    evt.ref,
                    evt.place,
                    evt.attendant,
                    evt.liters,
                    evt.pump,
                    evt.id_pump
                ]
            )
            }
        })
        
        if (data.list[0]?.km !== undefined){
            if (driver){
                prepare_data.push(
                    [
                        {text: 'Resumo do Período', colSpan: 4, fillColor: '#d5d5d5'}, 
                        {},
                        {},
                        {},
                        {text: data.resume.liters + ' litros', fillColor: '#d5d5d5' },
                        {text: data.resume.km_diff + ' km', fillColor: '#d5d5d5' },
                        {text: '', fillColor: '#d5d5d5'}, 
                    ]
                )
                prepare_data.push(
                    [
                        {text: 'Média do Período', colSpan: 4, fillColor: '#d5d5d5'}, 
                        {},
                        {},
                        {},
                        {text: data.resume.medium.toFixed(2) + ' Km por litro', fillColor: '#d5d5d5', colSpan: 2 },
                        {},
                        {text: '', fillColor: '#d5d5d5'}, 
                    ]
                )
            }else{
                prepare_data.push(
                        [
                            {text: 'Resumo do Período', colSpan: 4, fillColor: '#d5d5d5'}, 
                            {},
                            {},
                            {},
                            {text: data.resume.liters, fillColor: '#d5d5d5' },
                            {text: '', colSpan: 2, fillColor: '#d5d5d5'}, 
                            {},
                        ]
                )
            }
        }else{
            prepare_data.push(
                [
                    {text: 'Resumo do Período', colSpan: 5, fillColor: '#d5d5d5'}, 
                    {},
                    {},
                    {},
                    {},
                    {text: data.resume.liters, fillColor: '#d5d5d5' },
                    {text: '', colSpan: 2, fillColor: '#d5d5d5'}, 
                    {},
                ]
            )
        }
        
        let header = []
        let content = [
        {
			columns: [
				{
					width: '*',
					text: 'Relatório de Bomba de Abastecimento',
                    margin: [0, 50, 0, 0],
                    fontSize: 18,
			        bold: true,
				},
				(company) ? {
					width: 360,
                    heigth: 100,
                    margin: [0,0,10,0],
                    alignment: 'right',
					image: company,
				} : null,
                (logo) ? {
					width: 100,
                    heigth: 100,
					image: logo,
				} : null,
			]
		},
        {
            text: ".",
            color: "white"
        },
        {
			table: {
				headerRows: 1,
                widths: ["*"],
				body: [
					[(desc_ref+": " + ref) + ((driver) ? " - " + drivers.filter(d => d.c == driver)[0].name : "")],
				]
			},
			layout: {
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 2 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 2 : 1;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
				},
				vLineColor: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
				},
            }
		},
        {
            text: ".",
            color: "white"
        },
        {
			style: 'tableExample',
			color: '#444',
            fontSize: 8,
			table: {
				widths: (data.list[0]?.km !== undefined) ? ['*', '*', '*', '*', '*', '*', '*'] : ['*', '*', '*', '*', '*', '*', '*', '*'],
				headerRows: 2,
				// keepWithHeaderRows: 1,
				body: [ (data.list[0]?.km !== undefined) ?
                    [
                        {text: 'Data', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Dia Semana', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Placa', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Frentista', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Litros', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'KM', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Bomba', alignment: 'center', fillColor: '#b7b7b7'},
                    ]
                    :
                    [
                        {text: 'Data', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Dia Semana', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Placa', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Obra', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Frentista', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Litros', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Bomba', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'ID Bomba', alignment: 'center', fillColor: '#b7b7b7'},
                    ]
                    ,
                    ...prepare_data
				]
			}
		},
        ]
        let footer = []

        let fileDefinition = {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            pageMargins: [ 10, 10, 10, 20 ],
            
            header: [header],
            content: [content],
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }
    
	return (
        <div className="container">
            <h2>Relatório de Bomba de Abastecimento</h2>
            <div className='report-config'>
                <div className='input-form'>
                    <label>Selecione a Bomba</label>
                    <select onChange={(e) => {setVehicle(e.target.value);getSensor(e.target.value);getDrivers(customer)}} name="vehicle" id="vehicle">
                        <option>-------</option>
                        {
                            vehicles.map((vehicle) => {
                                return <option key={vehicle.id} value={vehicle.id}>{vehicle.nm}</option>
                            })
                        }
                    </select>
                </div>
                <div style={{display: (sensors.length > 0) ? "block" : "none", marginTop: '10px'}} className='input-form'>
                    <label>Obra</label>
                    <select onChange={(e) => setSensor(e.target.value)} name="sensor" id="sensor">
                        <option value="">-------</option>
                        {
                            sensors.map((sensor_map) => {
                                return <option key={sensor_map.ref} value={sensor_map.ref}>{sensor_map.ref} - {sensor_map.desc}</option>
                            })
                        }
                    </select>
                </div>
                <div style={{marginTop: '10px'}} className='input-form'>
                    <label>Filtrar</label>
                    <select onChange={(e) => setDriver(e.target.value)} name="driver" id="driver">
                        <option value="">-------</option>
                        {
                            drivers.map((vehicle, key) => {
                                return <option key={key} value={vehicle.c}>{vehicle.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Data Inícial</label>
                        <input onChange={(e) => setDate_in(e.target.value)} type="date" name="date_in" id="date_in" />
                    </div>
                    <div>
                        <label>Data Final</label>
                        <input onChange={(e) => setDate_out(e.target.value)} type="date" name="date_out" id="date_out" />
                    </div> 
                </div>
                <div className='input-form'>
                    <label>Tolerência em Segundos de um Registro</label>
                    <input disabled={true} onChange={(e) => setTolerance(e.target.value)} value={tolerance} type="number" name="tolerance" id="tolerance"></input>
                </div>
            </div>
            <fieldset>
                <legend>Detalhes Extra do PDF</legend>
                <div className='input-form-n2'>
                    <div>
                        <label>Logo da Empresa</label>
                        <input onChange={(e) => {readLogo(e)}} type="file" id="logo" name="logo" />
                    </div>
                </div>
                {/*<div className='input-form-n2'>
                    <div>
                        <label>Imagem com a Descrição da Empresa</label>
                        <input onChange={(e) => {readCompany(e)}} type="file" id="info-company" name="info-company" />
                    </div>
                </div>*/}
            </fieldset>
            <div className='action-buttons'>
                <button style={(runReport) ? {padding: "15px 15px 15px 40px", pointerEvents: "none"} : {padding: "15px 20px", pointerEvents: "initial"}} onClick={generateReport} className="generate-report" id="generate-report">
                    <svg style={(runReport) ? {display: "block"} : {display: "none"}} fill="#fff" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>reload</title>
                    <path d="M0 16q0-2.784 1.088-5.312t2.912-4.384 4.384-2.912 5.344-1.088q2.784 0 5.312 1.088t4.384 2.912 2.912 4.384 1.088 5.312h2.304q0.736 0 1.28 0.416t0.8 1.024 0.16 1.28-0.64 1.184l-4.576 4.576q-0.672 0.672-1.6 0.672t-1.632-0.672l-4.576-4.576q-0.512-0.512-0.608-1.184t0.128-1.28 0.8-1.024 1.312-0.416h2.272q0-2.464-1.216-4.576t-3.328-3.328-4.576-1.216-4.608 1.216-3.328 3.328-1.216 4.576 1.216 4.608 3.328 3.328 4.608 1.216q1.728 0 3.36-0.64l3.424 3.392q-3.136 1.824-6.784 1.824-2.816 0-5.344-1.088t-4.384-2.912-2.912-4.384-1.088-5.344z"></path>
                    </svg>
                    <span>Gerar Relatório</span>
                </button>
                <input disabled={pdfLocked} className='exportar-pdf' type='button' onClick={exportarPDF} name="export-pdf" id="export-pdf" value="Exportar PDF" />
            </div>
            
            <div className="table-overflow">   
                <table className='report-table'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Dia Semana</th>
                            <th>{(data.list[0]?.km !== undefined) ? 'Placa' : 'Liberado'}</th>
                            {(data.list[0]?.place !== undefined) ? <th>Obra</th> : null}
                            <th>Frentista</th>
                            <th>Litros</th>
                            {(data.list[0]?.km !== undefined) ? <th>KM</th> : null}
                            <th>Bomba</th>
                            {(data.list[0]?.id_pump !== undefined) ? <th>ID Bomba</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            generateHtml()
                        }
                    </tbody>
                </table>
            </div>
            <div style={{display: (modal) ? "flex" : "none"}} className='modal'>
                <div></div>
                <div>
                    <span onClick={() => {setModal(!modal)}}>X</span>
                    <div>
                        {
                            macros.map((macro, key) => {
                                return (
                                    <div key={key} className='macro-detail'>
                                        <div>{key + 1}</div>
                                        <div>
                                            <div>{macro['name']}</div>
                                            <span>{macro['date']}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}
