import React, { useState } from 'react';
import { Navigate } from 'react-router-dom'
//import axios from 'axios';
//import cfg from '../../utils/config'
import auth from '../../utils/auth'
const dns = "http://login.auvorastrear.com.br";

export default function Login(props) {

    const [toDashboard, setToDashboard] = useState(false);

    const postLogin = (e) => {
        /* eslint-disable */
        // construct login page URL

        e.target.style.pointerEvents = "none"

        var url = dns + "/login.html"; // your site DNS + "/login.html"
        url += "?client_id=" + "wialon";	// your application name
        url += "&access_type=" + -1;	// access level, 0x100 = "Online tracking only"
        url += "&activation_time=" + 0;	// activation time, 0 = immediately; you can pass any UNIX time value
        url += "&duration=" + 86400;	// duration, 604800 = one week in seconds
        url += "&flags=" + 0x1;			// options, 0x1 = add username in response
        
        url += "&redirect_uri=" + dns + "/post_token.html"; // if login succeed - redirect to this page

        // listen message with token from login page window
        window.addEventListener("message", receiveToken);
        
        // finally, open login page in new window
        window.open(url, "_blank", "width=760, height=500, top=300, left=500");    
        
        /* eslint-enable */
    }

    const receiveToken = (e) => {
        /* eslint-disable */
        // get message from login window

        let msg = e.data;
        if (typeof msg == "string" && msg.indexOf("access_token=") >= 0) {
            // get token
            let token = msg.replace("access_token=", "");
            // now we can use token, e.g show it on page
            //document.getElementById("token").innerHTML = token;
            //document.getElementById("login").setAttribute("disabled", "");
            //document.getElementById("logout").removeAttribute("disabled");
            
            // or login to wialon using our token
            wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");
            
            wialon.core.Session.getInstance().loginToken(token, "", function(code) {
                if (code)
                    return;
                let user = wialon.core.Session.getInstance().getCurrUser().getName();
                let user_id = wialon.core.Session.getInstance().getCurrUser().getId();
                let jsonuser = {"name": user, "id": user_id}
                auth.setToken(token, true)
                auth.set(jsonuser, '__auvoapp__profile', true)
                setToDashboard(true)
            });
            
            // remove "message" event listener
            window.removeEventListener("message", receiveToken);
        }
        /* eslint-enable */
    }

    const postLogout = () => {  
        /* eslint-disable */
        var sess = wialon.core.Session.getInstance();
        if (sess && sess.getId()) {
            sess.logout(function() {
                document.getElementById("logout").setAttribute("disabled", "");
                document.getElementById("login").removeAttribute("disabled");
            });
        }
        /* eslint-enable */
    }
    
	return (
        <>
            {toDashboard  ? <Navigate to="/" /> : null}
            <>  
                <div className='login-root'>
                    <div className='login-box'>
                        <div> <img className="logo-login" src="/logo_big.png" /></div>
                        <h2>Auvo Monitoramento</h2>
                        <h4>Acessar o Sistema</h4>
                        <input id="login" type="button" className="btn-login" value="Entrar utilizando Auvo" onClick={postLogin}/>
                        {/*Your token: <span id="token"></span>
                        <br /><br />
                        <input id="logout" type="button" value="Click to logout" disabled onClick={postLogout}/>*/}
                    </div>
                </div>
            </>
        </>
	)
}