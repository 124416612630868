import React from 'react';
import ReactDOMClient from 'react-dom/client';
import '../src/assets/style.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import auth from '../src/utils/auth'

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {

  if(error.response.status === 401){
      auth.clearAppStorage();
      window.location.reload();
  }

  return Promise.reject(error);
});

const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);