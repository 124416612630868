import React,{useState, useEffect, useRef} from 'react';
import { Routes, useLocation, Route, useFetcher } from 'react-router-dom';
import axios from 'axios';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import cfg from '../../utils/config'
import auth from '../../utils/auth'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function RelatorioJornadaTrabalho(props) {

    const [drivers, setDrivers] = useState([])
    const [customer, setCustomer] = useState(false)
    const [driver, setDriver] = useState(false)
    const [date_in, setDate_in] = useState(false)
    const [date_out, setDate_out] = useState(false)
    const [pernoite_in, setPernoite_in] = useState(false)
    const [pernoite_out, setPernoite_out] = useState(false)
    const [logo, setLogo] = useState("")
    const [fnDriver, setFnDriver] = useState("")
    const [company, setCompany] = useState("")
    const [data, setData] = useState({'j':{}, 'ws':{}, 'rs': {}})
    const [pdfLocked, setPdfLocked] = useState(true)
    const [runReport, setRunReport] = useState(false)
    const [modal, setModal] = useState(false)
    const [macros, setMacros] = useState([])
    const [extraHoursConfig, setExtraHoursConfig] = useState({'sf': 8, 'tf': 8, 'qt': 8, 'qit': 8, 'sxf': 8, 'sb': 8, 'dm': 0, 'fr': 0})
    const tableRef = useRef(null);

    useEffect(() => {
        getInitialData()
    }, [])

    const getInitialData = () => {
        let url = cfg.base_api_url + 'work-time/'
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        axios.get(url, config_me)
        .then((initial) => {
            setDrivers(initial.data.drivers)
            setCustomer(initial.data.id)
        })
        .catch((request) => {
            console.log(request)
        })
    }

    const showModal = (list) => {
        setMacros(list)
        setModal(!modal)
    }

    const generateReport = () => {
        setRunReport(true)
        let url = cfg.base_api_url + 'work-time/'
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        let data = new FormData()
        data.append('date_in', date_in)
        data.append('date_out', date_out)
        data.append('customer', customer)
        data.append('driver', driver)
        if(pernoite_in)
            data.append('pernoite_in', pernoite_in)
        if(pernoite_out)
            data.append('pernoite_out', pernoite_out)

        for (let day_var in extraHoursConfig){
            data.append(day_var, extraHoursConfig[day_var])
        }

        axios.post(url, data, config_me)
        .then((report) => {
            setData(report.data)
            setPdfLocked(false)
            setRunReport(false)
        })
        .catch((request) => {
            console.log(request)
            setRunReport(false)
        })
    }

    const generateHtml = () => {
        let html_list = []

        Object.keys(data['j']).sort().map((week) => {
            console.log(week)

            html_list.push(Object.keys(data['j'][week]).map((day, key) => {
                console.log(day)
                return data['j'][week][day]['journey'].map((dataday, key) => {
                    return (
                        <tr key={key}>
                            <td >{day.split('-')[2] +'/'+ day.split('-')[1] +'/'+ day.split('-')[0]} <span onClick={() => {showModal(dataday['macros'])}} className='detail-day'>?</span></td>
                            <td >{dataday['weekday']}</td>
                            <td >{dataday['vehicle']['item']['nm'].split(' - ')[0]}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['start_time_ptbr'] = e.target.textContent} contentEditable>{dataday['start_time_ptbr']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['end_time_ptbr'] = e.target.textContent} contentEditable>{dataday['end_time_ptbr']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['lunch'][0]['start_time_ptbr'] = e.target.textContent} contentEditable>{dataday['lunch'][0]['start_time_ptbr']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['lunch'][0]['end_time_ptbr'] = e.target.textContent} contentEditable>{dataday['lunch'][0]['end_time_ptbr']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['total_work_time'] = e.target.textContent} contentEditable>{dataday['total_work_time']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['total_driving'] = e.target.textContent} contentEditable>{dataday['total_driving']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['most_driving_time_route'] = e.target.textContent} contentEditable>{dataday['most_driving_time_route']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['stop_time'] = e.target.textContent} contentEditable>{dataday['stop_time']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['total_lunch'] = e.target.textContent} contentEditable>{dataday['total_lunch']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['pernoite'] = e.target.textContent} contentEditable>{dataday['pernoite']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['hours_extra'] = e.target.textContent} contentEditable>{dataday['hours_extra']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['total_resting'] = e.target.textContent} contentEditable>{dataday['total_resting']}</td>
                            <td onInput={(e)=>data['j'][week][day]['journey'][key]['total_waiting'] = e.target.textContent} contentEditable>{dataday['total_waiting']}</td>
                        </tr>
                    )
                })
            }))

            html_list.push(
                <tr key={'week_'+week} className='sub-th'>
                    <td colSpan="7">Resumo da Semana</td>
                    <td onInput={(e)=>data['ws'][week]['total_work_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['total_work_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['total_driving_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['total_driving_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['most_driving_time_route_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['most_driving_time_route_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['stop_time_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['stop_time_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['total_time_lunch_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['total_time_lunch_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['pernoite_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['pernoite_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['hours_extra_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['hours_extra_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['total_resting_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['total_resting_seconds_format']}</td>
                    <td onInput={(e)=>data['ws'][week]['total_waiting_seconds_format'] = e.target.textContent} contentEditable>{data['ws'][week]['total_waiting_seconds_format']}</td>
                </tr>
            )
        })

        html_list.push(
            <tr key={"final_resume"} className='sub-close'>
                <td colSpan="7">Resumo do Período</td>
                <td onInput={(e)=>data['rs']['total_work_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['total_work_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['total_driving_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['total_driving_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['most_driving_time_route_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['most_driving_time_route_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['stop_time_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['stop_time_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['total_time_lunch_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['total_time_lunch_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['pernoite_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['pernoite_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['hours_extra_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['hours_extra_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['total_resting_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['total_resting_seconds_format']}</td>
                <td onInput={(e)=>data['rs']['total_waiting_seconds_format'] = e.target.textContent} contentEditable>{data['rs']['total_waiting_seconds_format']}</td>
            </tr>
        )

        return html_list

    }

    const readLogo = (elem) => {
        
        if (!elem.target.files || !elem.target.files[0]) return;
          
        const FR = new FileReader();
          
        FR.addEventListener("load", function(evt) {
            setLogo(evt.target.result)
        })
          
        FR.readAsDataURL(elem.target.files[0]);
        
    }

    const readCompany = (elem) => {
        
        if (!elem.target.files || !elem.target.files[0]) return;
          
        const FR = new FileReader();
          
        FR.addEventListener("load", function(evt) {
            setCompany(evt.target.result)
        })
          
        FR.readAsDataURL(elem.target.files[0]);
        
    }

    const exportarPDF = () => {

        let prepare_data = []

        Object.keys(data['j']).sort().forEach((week) => {
            Object.keys(data['j'][week]).forEach((day, key) => {
                data['j'][week][day]['journey'].forEach((data_line, key) => {
                    prepare_data.push(
                        [
                            day.split('-')[2] +'/'+ day.split('-')[1] +'/'+ day.split('-')[0],
                            data_line['weekday'],
                            data_line['vehicle']['item']['nm'].split(' - ')[0],
                            data_line['start_time_ptbr'],
                            data_line['end_time_ptbr'],
                            data_line['lunch'][0]['start_time_ptbr'],
                            data_line['lunch'][0]['end_time_ptbr'],
                            data_line['total_work_time'],
                            data_line['total_driving'],
                            data_line['most_driving_time_route'],
                            data_line['stop_time'],
                            data_line['total_lunch'],
                            data_line['pernoite'],
                            data_line['hours_extra'],
                            data_line['total_resting'],
                            data_line['total_waiting'],
                        ]
                    )
                })
            })
            
            prepare_data.push(
                [
                    {text: 'Resumo da Semana', colSpan: 7, fillColor: '#b7b7b7' }, 
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: data['ws'][week]['total_work_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['total_driving_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['most_driving_time_route_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['stop_time_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['total_time_lunch_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['pernoite_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['hours_extra_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['total_resting_seconds_format'], fillColor: '#b7b7b7' },
                    {text: data['ws'][week]['total_waiting_seconds_format'], fillColor: '#b7b7b7' }
                ]
            )
        })

        prepare_data.push(
                [
                    {text: 'Resumo do Período', colSpan: 7, fillColor: '#d5d5d5'}, 
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: data['rs']['total_work_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['total_driving_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['most_driving_time_route_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['stop_time_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['total_time_lunch_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['pernoite_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['hours_extra_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['total_resting_seconds_format'], fillColor: '#d5d5d5' },
                    {text: data['rs']['total_waiting_seconds_format'], fillColor: '#d5d5d5' }
                ]
        )
        
        let header = []
        let content = [
        {
			columns: [
				{
					width: '*',
					text: 'Jornada de Trabalho',
                    margin: [10, 50, 0, 0],
                    fontSize: 18,
			        bold: true,
				},
				/*{
					width: 360,
                    heigth: 100,
                    margin: [0,0,10,0],
                    alignment: 'right',
					image: company,
				},*/
				(logo) ? {
					width: 100,
                    heigth: 100,
					image: logo,
				} : null,
			]
		},
        {
            text: ".",
            color: "white"
        },
        {
			table: {
				headerRows: 1,
                widths: ["*"],
				body: [
					["Motorista: " + drivers.filter((driver_t) => driver_t.id == driver)[0]['name'] + " - " + fnDriver],
				]
			},
			layout: {
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 2 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 2 : 1;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
				},
				vLineColor: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
				},
            }
		},
        {
            text: ".",
            color: "white"
        },
        {
			style: 'tableExample',
			color: '#444',
            fontSize: 8,
			table: {
				widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
				headerRows: 2,
				// keepWithHeaderRows: 1,
				body: [
					[
                        {text: '\nData', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {text: '\nDia Semana', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {text: '\nPlaca', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {text: '\nInício Jornada', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {text: '\nFim Jornada', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {text: 'Refeição', colSpan: 2, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {},
                        {text: 'Tempo Total', colSpan: 9, alignment: 'center', fillColor: '#d5d5d5'}, 
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                        {},
                    ],
                    [
                        {},
                        {},
                        {},
                        {},
                        {},
                        {text: 'Início', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Fim', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Jornada', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Em Direção', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Continua', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Parado', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Refeição', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Pernoite', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Hora Extra', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Descanso', alignment: 'center', fillColor: '#b7b7b7'},
                        {text: 'Espera', alignment: 'center', fillColor: '#b7b7b7'},
                    ],
                    ...prepare_data
				]
			}
		},
        {
            text: ".",
            color: "white"
        },
        {
            text: ".",
            color: "white"
        },
        {
            text: ".",
            color: "white"
        },
        {
            table : {
                headerRows : 1,
                widths: [400],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
        },
        {
            text: drivers.filter((driver_t) => driver_t.id == driver)[0]['name'],
        },
        ]
        let footer = []

        let fileDefinition = {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            pageMargins: [ 10, 10, 10, 20 ],
            
            header: [header],
            content: [content],
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }

	return (
        <div className="container">
            <h2>Relatório de Jornada de Trabalho</h2>
            <div className='report-config'>
                <div className='input-form'>
                    <label>Selecione o Motorista</label>
                    <select onChange={(e) => setDriver(e.target.value)} name="driver" id="driver">
                        <option>-------</option>
                        {
                            drivers.map((driver) => {
                                return <option key={driver.id} value={driver.id}>{driver.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Data Inícial</label>
                        <input onChange={(e) => setDate_in(e.target.value)} type="date" name="date_in" id="date_in" />
                    </div>
                    <div>
                        <label>Data Final</label>
                        <input onChange={(e) => setDate_out(e.target.value)} type="date" name="date_out" id="date_out" />
                    </div> 
                </div>
            </div>
            <div className='report-config-adv input-form-n2'>
                <div>
                    <label>Início Pernoite</label>
                    <input onChange={(e) => setPernoite_in(e.target.value)} type="time" name="pernoite_in" id="pernoite_in" />
                </div>
                <div>
                    <label>Fim Pernoite</label>
                    <input onChange={(e) => setPernoite_out(e.target.value)} type="time" name="pernoite_out" id="pernoite_out" />
                </div>
            </div>
            <fieldset>
                <legend>Detalhes Extra do PDF</legend>
                <div className='input-form-n2'>
                    <div>
                        <label>Logo da Empresa</label>
                        <input onChange={(e) => {readLogo(e)}} type="file" id="logo" name="logo" />
                    </div>
                </div>
                {/*<div className='input-form-n2'>
                    <div>
                        <label>Imagem com a Descrição da Empresa</label>
                        <input onChange={(e) => {readCompany(e)}} type="file" id="info-company" name="info-company" />
                    </div>
                    </div>*/}
                <div className='input-form-n2'>
                    <div>
                        <label>Função do Motorista</label>
                        <input type="text" onChange={(e) => setFnDriver(e.target.value)} value={fnDriver} id="info-driver" name="info-driver" />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Configuração de Horas Extras</legend>
                <div className='input-form-n2'>
                    <div>
                        <label>Segunda-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"sf": parseInt(e.target.value)}})} value={extraHoursConfig.sf} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Terça-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"tf": parseInt(e.target.value)}})} value={extraHoursConfig.tf} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Quarta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"qt": parseInt(e.target.value)}})} value={extraHoursConfig.qt} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Quinta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"qit": parseInt(e.target.value)}})} value={extraHoursConfig.qit} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Sexta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"sxf": parseInt(e.target.value)}})} value={extraHoursConfig.sxf} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Sábado</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"sb": parseInt(e.target.value)}})} value={extraHoursConfig.sb} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Domingo</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"dm": parseInt(e.target.value)}})} value={extraHoursConfig.dm} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Feriados</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({...extraHoursConfig, ...{"fr": parseInt(e.target.value)}})} value={extraHoursConfig.fr} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
            </fieldset>
            <div className='action-buttons'>
                <button style={(runReport) ? {padding: "15px 15px 15px 40px", pointerEvents: "none"} : {padding: "15px 20px", pointerEvents: "initial"}} onClick={generateReport} className="generate-report" id="generate-report">
                    <svg style={(runReport) ? {display: "block"} : {display: "none"}} fill="#fff" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>reload</title>
                    <path d="M0 16q0-2.784 1.088-5.312t2.912-4.384 4.384-2.912 5.344-1.088q2.784 0 5.312 1.088t4.384 2.912 2.912 4.384 1.088 5.312h2.304q0.736 0 1.28 0.416t0.8 1.024 0.16 1.28-0.64 1.184l-4.576 4.576q-0.672 0.672-1.6 0.672t-1.632-0.672l-4.576-4.576q-0.512-0.512-0.608-1.184t0.128-1.28 0.8-1.024 1.312-0.416h2.272q0-2.464-1.216-4.576t-3.328-3.328-4.576-1.216-4.608 1.216-3.328 3.328-1.216 4.576 1.216 4.608 3.328 3.328 4.608 1.216q1.728 0 3.36-0.64l3.424 3.392q-3.136 1.824-6.784 1.824-2.816 0-5.344-1.088t-4.384-2.912-2.912-4.384-1.088-5.344z"></path>
                    </svg>
                    <span>Gerar Relatório</span>
                </button>
                <input disabled={pdfLocked} className='exportar-pdf' type='button' onClick={exportarPDF} name="export-pdf" id="export-pdf" value="Exportar PDF" />
                <DownloadTableExcel
                    filename="worktime"
                    sheet="worktime"
                    currentTableRef={tableRef.current}
                >

                   <button disabled={pdfLocked} className='exportar-excel'> Gerar Excel </button>

                </DownloadTableExcel>
            </div>
            
            <div className="table-overflow">   
                <table ref={tableRef} className='report-table'>
                    <thead>
                        <tr>
                            <th rowSpan="2">Data</th>
                            <th rowSpan="2">Dia Semana</th>
                            <th rowSpan="2">Placa</th>
                            <th rowSpan="2">Início Jornada</th>
                            <th rowSpan="2">Fim Jornada</th>
                            <th colSpan="2">Refeição</th>
                            <th colSpan="9">Tempo Total</th>
                        </tr>
                        <tr className='sub-th'>
                            <th >Início</th>
                            <th >Fim</th>
                            <th >Jornada</th>
                            <th >Em Direção</th>
                            <th >Continua</th>
                            <th >Parado</th>
                            <th >Refeição</th>
                            <th >Pernoite</th>
                            <th >Hora Extra</th>
                            <th >Descanso</th>
                            <th >Espera</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            generateHtml()
                        }
                    </tbody>
                </table>
            </div>
            <div style={{display: (modal) ? "flex" : "none"}} className='modal'>
                <div></div>
                <div>
                    <span onClick={() => {setModal(!modal)}}>X</span>
                    <div>
                        {
                            macros.map((macro, key) => {
                                return (
                                    <div key={key} className='macro-detail'>
                                        <div>{key + 1}</div>
                                        <div>
                                            <div>{macro['name']}</div>
                                            <span>{macro['date']}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}
